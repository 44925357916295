/**
 * @file Anything that can run on every page.
 */

import table from '../components/table/table';

/**
 * Global functions.
 *
 * Runs component js and any global tasks.
 */
const vInterior = () => {
	const wpPostData = document.body.dataset;

	table();
	if (document.querySelector('.v-morph-intro')) import('../components/morph-intro/morph-intro').then((m) => m.default());
	if (document.querySelectorAll('.v-morph-bg')) import('../components/morph-bg/morph-bg').then((m) => m.default());
	if (document.querySelector('.v-text-hero')) import('../components/text-hero/text-hero').then((m) => m.default());
	if (document.querySelector('.v-team-finder')) import('../components/team-finder/team-finder').then((m) => m.default());
	if (document.querySelector('.v-accordions') && document.querySelectorAll('.v-accordions .v-accordions__trigger').length) import('../components/accordions/accordions').then((m) => m.default());
	if (document.querySelector('.facetwp-facet-i_category') && document.querySelector('.facetwp-facet-i_subcategory')) import('../components/filters/filters').then((m) => m.default());
	if (document.querySelector('.v-morph-video')) import('../components/morph-video/morph-video').then((m) => m.default());
	if (document.querySelectorAll('.v-lp-post-cards').length) import('../components/lp-post-cards/lp-post-cards-scroll').then((m) => m.default());
	if (document.querySelectorAll('.v-tutorial-wrap').length) import('../components/tutorial-wrap/tutorial-wrap').then((m) => m.default());
	if (document.querySelectorAll('.v-js-trigger-scroll').length) import('../components/products/products-scroll-to').then((m) => m.default());
	if (document.querySelector('.v-products')) import('../components/products/products').then((m) => m.default());
	if (document.querySelector('.v-accolades')) import('../components/accolades/accolades').then((m) => m.default());

	if (wpPostData.pageTemplate === 'template-security-center.php' || wpPostData.pageTemplate === 'template-faqs.php') {
		if (document.querySelector('.v-qa-sidebar')) import('./partials/question-answer').then((m) => m.default());
	}
};

if (document.readyState !== 'loading') {
	vInterior();
} else {
	document.addEventListener('DOMContentLoaded', vInterior);
}
